import React from 'react'
import 'react-image-gallery/styles/scss/image-gallery.scss'
import './CarouselGallery.scss'
import ImageGallery from 'react-image-gallery'

const CarouselGallery = ({ ...otherProps }) => {
    return (
        <div className='carousel-gallery-wrapper'>
            <ImageGallery {...otherProps} />
        </div>
    )
}

export default CarouselGallery
