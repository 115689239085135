import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link, navigate, graphql } from 'gatsby'
import CarouselGallery from '../../components/CarouselGallery'
import './index.scss'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'

import IconEnvelope from '../../assets/icons/envelope-circled.svg'
import IconListCheck from '../../assets/icons/list-check-circled.svg'
import IconClock from '../../assets/icons/clock-circled.svg'
import IconQuantity1 from '../../assets/icons/head-1.svg'
import IconQuantity2 from '../../assets/icons/collage-2.svg'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Card from '../../components/Card'

function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

const PriceCard = ({ title, titleBgColor, body, priceVariantElements, disclaimerText }) => {
    return (
        <Card className='price-card' title={
            <h3 className='mb-0 text-center text-lg-left'>{title}</h3>
        } titleStyle={{ backgroundColor: titleBgColor }}>
            <Row>
                <Col className='mb-4' xs={12} md={6}>
                    {body}
                </Col>
                <Col className='col-price-variants mb-4' xs={12} md={6}>
                    {priceVariantElements.map((element, idx) => React.cloneElement(element, { key: idx }))}
                </Col>
            </Row>
            <Row>
                <Col className='d-flex flex-column align-items-center d-md-block' xs={12} md={6}>
                    <Button href='#kontakt-aufnehmen' variant='secondary' className='mb-3'>Jetzt Portrait anfragen</Button>
                </Col>
                <Col xs={12} md={6}>
                    <p className='disclaimer text-md-center'>{disclaimerText}</p>
                </Col>
            </Row>
        </Card>
    )
}
PriceCard.propTypes = {
    title: PropTypes.string,
    titleBgColor: PropTypes.string,
    body: PropTypes.element,
    priceVariantElements: PropTypes.arrayOf(PropTypes.element),
    disclaimerText: PropTypes.string,
}

class InquiryPage extends Component {
    constructor(props) {
        super(props)
        this.state = { isValidated: false }
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleSubmit = e => {
        e.preventDefault()
        e.stopPropagation()
        const form = e.currentTarget

        if (form.checkValidity() === true) {
            fetch('/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: encode({
                    'form-name': form.getAttribute('name'),
                    ...this.state,
                }),
            })
                .then(() => navigate(form.getAttribute('action')))
                .catch(error => alert(error))
        }
        this.setState({ isValidated: true })
    }

    render() {
        const { allContentfulInquiryExplanationPhoto: { explanationPhotos } } = this.props.data

        const images = explanationPhotos.map((explanationPhoto) => ({
            original: explanationPhoto.image.localFile.childImageSharp.resize.src,
            thumbnail: explanationPhoto.image.localFileThumbnailSize.childImageSharp.resize.src,
            description: explanationPhoto.description.description,
            originalTitle: explanationPhoto.description.description,
            originalClass: `${explanationPhoto.criteria === 'positive' ? 'mark-positive' : 'mark-negative'}`,
            thumbnailClass: `${explanationPhoto.criteria === 'positive' ? 'mark-positive' : 'mark-negative'}`,
        }))

        return (
            <Layout location={this.props.location}>
                <SEO
                    title='Portraitanfrage'
                    description='In nur drei Schritten zu deinem handgezeichneten Tierportrait von TIERGETREU. '
                />
                <div className='inquiry-page'>
                    <Container className='mb-5'>
                        <Jumbotron className='mb-0 bg-white'>
                            <h1 className='offering-heading'>Dein Tier als Kunstwerk!<br /> <span
                                className='text-tint-secondary'>Portrait anfragen.</span></h1>
                            <p className=''>
                                In nur drei Schritten zu einem liebevoll gestalteten Portrait deines Lieblingsvierbeiners.
                            </p>
                            {/* TODO: Implement smooth scroll-down to contact-section of this page further down below… */}
                            <p className='mb-0'><Button href='#kontakt-aufnehmen'>Kontakt aufnehmen</Button></p>
                        </Jumbotron>
                        <section className='steps'>
                            <div className='stepper'>
                                <div className='item'>
                                    <IconEnvelope role='presentation' alt='' />
                                    {/* <img src={process.env.PUBLIC_URL + 'content/pages/inquiry-page/envelope-solid.svg'} role='presentation' alt='' /> */}
                                    <div className='text-container'>
                                        <div className='title'>1. Schreib mir eine Nachricht!</div>
                                        <p className='description'>
                                            Dir schwirren noch Fragen durch den Kopf? Gerne sorge ich für Klarheit. Schreib mir einfach, was dich beschäftigt.<br />
                                            Du weißt schon genau, was du möchtest? Super! Ich bin gespannt für welchen Stil du dich entschieden hast.
                                        </p>
                                    </div>
                                </div>
                                <div className='item'>
                                    <IconListCheck role='presentation' alt='' />
                                    {/* <img src={process.env.PUBLIC_URL + 'content/pages/inquiry-page/list-check-solid-2.svg'} role='presentation' alt='' /> */}
                                    <div className='text-container'>
                                        <div className='title'>2. Wir klären deine Wünsche.</div>
                                        <p className='description'>
                                            Das wichtigste für dein Portrait ist natürlich deine Fotovorlage. Wenn du dir nicht sicher bist, schick mir gerne auch mehrere Fotos. Ich mache dir einen Vorschlag, welches sich für eine Zeichnung am besten eignet. Wenn alles passt, kann es auch schon losgehen. Denk bitte daran, dass ich zu Beginn meiner Arbeit eine Anzahlung von 30 Prozent des Preises benötige.
                                        </p>
                                    </div>
                                </div>
                                <div className='item'>
                                    <IconClock role='presentation' alt='' />
                                    {/* <img src={process.env.PUBLIC_URL + 'content/pages/inquiry-page/envelope-check-solid-2.svg'} role='presentation' alt='' /> */}
                                    <div className='text-container'>
                                        <div className='title'>3. Mit Liebe erstelle ich dein Tierportrait.</div>
                                        <p className='description'>
                                            Jetzt kannst du dich entspannt zurücklegen. Sobald ich den letzten Strich gezeichnet habe, verpacke ich dein Portrait sorgfältig und wenig später hältst du es schon in den Händen.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Container>
                    <section>
                        <Jumbotron className='faq-notice'>
                            <div className='w-100 d-flex flex-column'>
                                <i className='far fa-comment mb-3' />
                                <p>Hast du noch weitere Fragen?</p>
                                <Button className='align-self-center' as={Link} to='/faq' >Zu den FAQ</Button>
                            </div>
                        </Jumbotron>
                    </section>
                    <section className='offering'>
                        <Container>
                            <h2 className='mb-4'>Meine Maltechniken. <br /><span
                                className='text-tint-secondary'>Preise.</span></h2>
                            <PriceCard title='Digital' titleBgColor='#23495A'
                                body={
                                    <ul>
                                        <li>Auf dem Tablet gezeichnetes, digitales Tierportrait</li>
                                        <li>Farbiger Hintergrund deiner Wahl</li>
                                        <li>Jedes Format möglich | Von Postkarte bis Poster</li>
                                        <li>Du erhältst das fertige Portrait als hochauflösende PNG-Datei | Keine Versandkosten :)</li>
                                    </ul>
                                }
                                priceVariantElements={[
                                    <div>
                                        <div className='title mb-2'>1&#x2011;Kopf Portrait</div>
                                        <IconQuantity1 role='presentation' alt='' />
                                        <div className='price-container'><div className='price'>97</div><div className='currency'>€</div></div>
                                    </div>,
                                    <div>
                                        <div className='title mb-2'>2&#x2011;Kopf Collage</div>
                                        <IconQuantity2 role='presentation' alt='' />
                                        <div className='price-container'><div className='price'>149</div><div className='currency'>€</div></div>
                                    </div>
                                ]}
                                disclaimerText='Die Mehrwertsteuer wird gemäß § 19 UStG nicht erhoben.'
                            />
                            <PriceCard title='Aquarell' titleBgColor='#474747'
                                body={
                                    <ul>
                                        <li>Von Hand gezeichnetes, persönliches Tierportrait</li>
                                        <li>1 bis 2-farbiger Hintergrund deiner Wahl</li>
                                        <li>Format 24cm x 32cm | ideal für Rahmung mit Passepartout</li>
                                        <li>Versicherter Versand des Werkes mit Deutscher Post</li>
                                    </ul>
                                }
                                priceVariantElements={[
                                    <div>
                                        <div className='title mb-2'>1&#x2011;Kopf Portrait</div>
                                        <IconQuantity1 role='presentation' alt='' />
                                        <div className='price-container'><div className='price'>167</div><div className='currency'>€</div></div>
                                    </div>
                                ]}
                                disclaimerText='Preise verstehen sich zzgl. Versand. Die Mehrwertsteuer wird gemäß § 19 UStG nicht erhoben.'
                            />
                            <PriceCard title='Farbstifte' titleBgColor='#547583'
                                body={
                                    <ul>
                                        <li>Von Hand gezeichnetes, persönliches Tierportrait</li>
                                        <li>Ohne Hintergrund </li>
                                        <li>Format 24cm x 32cm | ideal für Rahmung mit Passepartout</li>
                                        <li>Versicherter Versand des Werkes mit Deutscher Post</li>
                                    </ul>
                                }
                                priceVariantElements={[
                                    <div>
                                        <div className='title mb-2'>1&#x2011;Kopf Portrait</div>
                                        <IconQuantity1 role='presentation' alt='' />
                                        <div className='price-container'><div className='price'>147</div><div className='currency'>€</div></div>
                                    </div>
                                ]}
                                disclaimerText='Preise verstehen sich zzgl. Versand. Die Mehrwertsteuer wird gemäß § 19 UStG nicht erhoben.'
                            />
                        </Container>
                    </section>
                    <Container>
                        <section className='photo-how-to'>
                            <Row>
                                <Col>
                                    <h2 className='mb-4'>Wie sieht eine gute Fotovorlage aus? <br /><span
                                        className='text-tint-secondary'>Tipps.</span></h2>
                                    <p className='mb-5'>
                                        Du fragst dich, ob deine Fotos für eine Vorlage geeignet sind? Grundsätzlich
                                        gilt, je höher die Auflösung, desto besser. Aber keine Sorge, auch ein gutes
                                        Handyfoto ist machbar. Worauf du weiterhin achten solltest, siehst du an
                                        den folgenden Beispielen.
                                    </p>
                                </Col>
                                <Col>
                                    <CarouselGallery
                                        items={images}
                                        lazyLoad
                                        thumbnailPosition='bottom'
                                        showFullscreenButton={false}
                                        showPlayButton={false}
                                    />
                                </Col>
                            </Row>
                        </section>
                    </Container>
                    <Container>
                        <section className='section-contact' id='kontakt-aufnehmen'>
                            <Jumbotron>
                                <h2 className='mb-4'>Portrait anfragen</h2>
                                <Form
                                    noValidate
                                    validated={this.state.isValidated}
                                    name='contact-inquiry'
                                    method='post'
                                    action='/anfragen/danke/'
                                    data-netlify='true'
                                    data-netlify-honeypot='bot-field'
                                    onSubmit={this.handleSubmit}
                                >
                                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                                    <input type='hidden' name='form-name' value='contact-inquiry' />
                                    <div hidden>
                                        <label>
                                            Don’t fill this out:{' '}
                                            <input name='bot-field' onChange={this.handleChange} />
                                        </label>
                                    </div>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={12} md={6}>
                                            <Form.Label className='label' htmlFor={'name'}>
                                                Dein Name
                                            </Form.Label>
                                            <Form.Control
                                                type={'text'}
                                                name={'name'}
                                                onChange={this.handleChange}
                                                id={'name'}
                                                required={true}
                                            />
                                            <Form.Text className='text-muted'>
                                                * Pflichtfeld
                                            </Form.Text>
                                            <Form.Control.Feedback type='invalid'>
                                                Gib bitte deinen Namen an.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} xs={12} md={6}>
                                            <Form.Label htmlFor={'email'}>
                                                Deine E-Mail
                                                        </Form.Label>
                                            <Form.Control
                                                className='input'
                                                type={'email'}
                                                name={'email'}
                                                onChange={this.handleChange}
                                                id={'email'}
                                                required={true}
                                            />
                                            <Form.Text className='text-muted'>
                                                * Pflichtfeld
                                            </Form.Text>
                                            <Form.Control.Feedback type='invalid'>
                                                Gib bitte deine E-Mail-Adresse an.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label htmlFor={'message'}>
                                                Nachricht
                                            </Form.Label>
                                            <Form.Control
                                                as='textarea'
                                                rows='5'
                                                name={'message'}
                                                onChange={this.handleChange}
                                                id={'message'}
                                                placeholder='Hast du besondere Wünsche oder eine Frage? Ich freue mich auf deine Nachricht.'
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className='mt-4 mb-2'>
                                        <Form.Group as={Col}>
                                            <Form.Check
                                                required
                                                label='Ich stimme zu, dass meine Angaben aus dem Formular zur Beantwortung meiner
                                                    Anfrage erhoben und verarbeitet werden. Detaillierte Informationen zum
                                                    Widerrufsrecht und zum Umgang mit Nutzerdaten finden sich in
                                                    der Datenschutzerklärung.'
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col xs={12}>
                                            <Button className='w-100' type='submit'>
                                                Senden
                                            </Button>
                                        </Col>
                                    </Form.Row>
                                </Form>
                            </Jumbotron>
                        </section>
                    </Container>
                </div>
            </Layout>
        )
    }
}

export default InquiryPage
export const pageQuery = graphql`
    query InquiryPageQuery {
        allContentfulInquiryExplanationPhoto(sort: {fields: order, order: ASC}) {
            explanationPhotos: nodes {
                description {
                    description
                    childMarkdownRemark {
                        html
                    }
                }
                criteria
                image {
                    localFile {
                        childImageSharp {
                            resize(width: 600) {
                                src
                            }
                        }
                        publicURL
                    }
                    localFileThumbnailSize: localFile {
                        childImageSharp {
                            resize(width: 100) {
                                src
                            }
                        }
                        publicURL
                    }
                }
            }
        }
    }
`
