import React from 'react'
import PropTypes from 'prop-types'
import './Card.scss'

import classNames from 'classnames'

const Card = ({ className, title, children, titleStyle, ...otherProps }) => {

    return (
        <div
            className={classNames(['tg-card', className])}
            {...otherProps}
        >
            <div className='tg-card-title' style={titleStyle}>{title}</div>
            <div className='tg-card-body'>
                {children}
            </div>
        </div>
    )
}

Card.propTypes = {
    className: PropTypes.string,
    /// The content of the Card Title.
    title: PropTypes.node,
    children: PropTypes.node,
    titleStyle: PropTypes.object,
}

export default Card
